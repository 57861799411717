section{
	min-height: 80vh;
}
.container{
	position:relative;
	margin: 0 auto;
	width: 90%;
	max-width: 1400px;
}

.card{
	background:white;
	padding: 40px 5% 40px 20px;
	box-shadow: 0px 3px 33px rgba(0,0,0,.34);
	transition:all 0.3s ease-in-out;
}

.img-slider{
	position:relative;
	display: block;
	width: 100%;
	overflow:hidden;
}

// home
#home{
	img{
		display: block;
    width: 100%;
    height: auto;
	}
}
.home-intro{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.7);
  bottom: 0;
  right: 0;
  width: 100%;
  padding-left: 5%;
  padding-top: 15%;
  padding-right: 50%;
  background: -moz-linear-gradient(left, rgba(0,0,0,0.75) 31%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.75) 31%,rgba(0,0,0,0) 100%);
  background: linear-gradient(to right, rgba(0,0,0,0.75) 31%,rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf000000', endColorstr='#00000000',GradientType=1 );
  
}
// rooms 
.rooms-section{
	min-height: calc(100vh - 110px);	// 50 px of the header + 60px of padding
	overflow:hidden;	
	position:relative;
	width: 100%;

	#room-slider {
		display:table;
		position:relative;
		height: 80vh;

		.flex-viewport{
			display: table-cell;
			vertical-align: middle;
		}
	}
	.container{
		display: flex;
		flex-flow: row nowrap;
		
	}
	.room-content{				
		display:inline-block;		
		align-self:stretch;
		width: 40%;
		ul{
			padding-left: 0;
			list-style:inside disc;
			margin-bottom: 20px;	
			overflow:hidden;
			li{
				// Uncomment these if you want 2 columns amenities
				// float:left;
				// width: 50%;    		
			}
		}
	}
	img{
		box-shadow: 0px 3px 14px rgba(0,0,0,.34);		
		height: auto;				
		width: 65%;
		align-self:center;
		margin-left: -5%;
	}
}


// Standard content
.standard-content{
	background-position: top right;
	background-repeat: no-repeat;
	background-size: cover;	
	padding: 60px 0;

	.card{
		width: 40%;
	}
}

// amenities 2 columns
.two-column-list{
	overflow:hidden;
	li{
		float:left;
		width: 50%;
		padding-right: 10px;
	}
}

// specials
.specials{
	padding: 60px 0;
	position:relative;
	min-height: 80vh;

	.container{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
	.card{
		width: 32%;
		padding:40px  20px;
		&:hover{
			box-shadow: 0px 3px 16px rgba(0,0,0,.34);
		}
	}
	.special-price{
		text-align: center;
		position:relative;
		border:1px solid #dadada;
		padding: 10px 0 30px;
		margin: 0 auto;
		h2{
			margin: 0;
		}
		p{
			margin: 0;
		}
		button{
			position:absolute;
			bottom:-30px;
			left: 20%;
			width: 60%;
			// line-height: 3
		}
	}
}

// Contact Info
.contact-info{
	padding:0;
	height: 80vh;
	position:relative;
	display:block;

	.card{
		top: 50px;
		z-index: 1;
		padding:40px  20px;
		position:relative;
		left: 5%;
		width: 35%;
		max-width: 400px;
	}
	

	#hotel-map{
		display:block;
		height: 100%;
		left: 0;
		position:absolute;
		top:0;
		width: 100%;
		z-index: 0;
	}
}
/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
	.home-intro{
		padding-top: 5%;
	}
	#home{
		img{
	    height: 80vh;
		}
	}
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	.home-intro{
		position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 0;    
    background: white;
  }
  #home{
		img{
	    height: auto;
		}
	}
	.img-slider{
		height: auto;
	}
	.rooms-section{
		.container{
			flex-flow: column nowrap;
		}
		.room-content{
			width: 100%;
			order:1;
			margin-top: -20px;
		}
		img{
			display: block;
			margin: 0 auto;
			position:relative;
			width: 90%;
			order:0;
		}
	}
	.standard-content{
		background-size: cover;
		.card{
			width: 60%;
		}
	}
	.specials{
		.card{
			width: 45%;
			margin-bottom: 40px;
		}
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.standard-content{
		background-size: cover;
		.card{
			width: 100%;
		}
	}
	.specials{
		.card{
			width: 100%;
			margin-bottom: 40px;
		}
	}
	.contact-info{
		.card{
			width: 90%;
		}
		#hotel-map{
			position: relative;
		}
	}
}