body {
   font-family: "Quicksand";
   font-size: 1em;
   font-style: normal;
   font-weight: 400;
}

h1 {
   font-size: 1.88em;
   line-height: 1.3;
   text-transform: uppercase;
   font-family: "Quicksand";
   font-weight: 300;
   font-style: normal;
}

p.intro {
   font-size: 1.368em;
   line-height: 1.5em;
   margin: 1.038em 0px;
   padding: 1.154em 0;
}

p {
   font-size: 1em;
   line-height: 1.5em;
   margin: 0px 0px .75em 0px;
   font-family: Quicksand;
   font-weight: 400;
   font-style: normal;
}

h2 {
   font-size: 1.526em;
   line-height: 1.3em;
   margin: 0;
   font-family: "Quicksand";
   font-weight: 700;
   font-style: normal;
}

p.pullquote {
   padding-left: 10px;
   border-style: solid;
   border-left-width: 3px;
   font-size: 1em;
   line-height: 1.421em;
   margin: 0px 0px 1.421em 0px;
}

p.author {
   text-align: center;
   font-size: 13px;
   letter-spacing: 2px;
   margin-top: 27px;
   text-transform: uppercase;
}


ul{
   padding: 0 0 .75em 1em;
   margin: 0;
   list-style: outside;
   line-height: 2;
}


#home{
   h1{
      margin: 1.5em 0 0;
   }
   p.intro{
      margin: .5em 0 1.5em;
      padding: 0; 
   }
}

.contact-info{
   dt{
      text-transform: uppercase;
   }
   dd{
      padding: 5px 0 10px;
      margin:0 0 20px;
      line-height: 1.5;
      border-bottom: 1px solid rgba(0,0,0,.1);
   }
}