button,.btn{
	border:none;
	cursor: pointer;
	display: inline-block;
	font-family: "Quicksand";
	font-size:.875em;
	font-weight:700;
	padding: 20px 30px;
	text-transform:uppercase;
	transition:all 0.3s ease-in-out;	

	&:hover{
		box-shadow: 0px 3px 16px rgba(0,0,0,.34);
	}
}