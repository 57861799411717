.header {
	background: white;
	height: 50px;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 11;

	.logo {
		background: white;
		box-shadow: 0 3px 33px rgba(0, 0, 0, .34);
		float: left;
		margin-left: 5%;
		padding: 30px 20px;
	}

	nav {
		float: right;

		ul {
			display: inline-block;
			list-style: none inside;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;

				a {
					display: block;
					font-size: .875em;
					letter-spacing: .0465em;
					padding: 11px 10px;
					position: relative;
					text-decoration: none;
					text-transform: uppercase;

					&::before, 
					&::after {
						border: 1px solid transparent;
						content: '';
						left: 0;
						position: absolute;
						transition: all .3s ease-in-out;
						width: 100%;
					}

					&::before {
						top: 0;
					}

					&::after {
						bottom: 0;
					}

					&::hover,
					&.active {
						&::before {
							top: 10px;
						}
						&::after {
							bottom: 10px;
						}
					}
				}
			}
		}
	}
}

// hamburger icon
.mobile-menu {
	background: transparent;
	border: none;
	display: none;
	position: fixed;
	right: 10px;
	top: 10px;
	transition: all .3s ease-in-out;
	width: 30px;
	z-index: 101;

	span {
		background: white;
		border-radius: 5px;
		display: block;
		height: 4px;
		opacity: 1;
		position: absolute;
		transform-origin: left center;
		transition: all .3s ease-in-out;
		width: 100%;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 8px;
		}

		&:nth-child(3) {
			top: 16px;
		}
	}

		&.close-nav {
			span {
				background: white;
			}

			span:nth-child(2) {
				opacity: 0;
			}

			span:first-child,
			span:last-child {
				position: absolute;
			}

			span:first-child {
				top: 0;
				transform: rotate(45deg);
			}

			span:last-child {
				top: 21px;
				transform: rotate(-45deg);
			}
		}

		&:focus {
			background: none;
			border: none;
			box-shadow: none;
			outline: none;
		}

		&:hover {
			background: none;
		}
}

#book-now-hdr {
	float: right;
	height: 50px;
	padding: 0 40px;
}

	/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
	.mobile-menu {
		display: block;
	}

	.header {
		.logo {
			padding: 20px 10px;
		}

		nav {
			ul {
				background: rgba(0, 0, 0, .9);
				display: none;
				height: 100%;
				left: 0;
				padding-top: 10%;
				position: fixed;
				top: 0;
				width: 100%;
				z-index: 100;

				li {
					display: block;
					text-align: center;

					a {
						font-size: 1.65em;
					}
				}
			}
		}
	}

	#book-now-hdr {
		margin-right: 50px;
	}
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	nav {
		ul {
			li {
				a {
					&::before,
					&::after {
						display: none;
					}
				}
			}
		}
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	#book-now-hdr {
		bottom: 0;
		height: 40px;
		left: 0;
		position: fixed;
		width: 100%;
	}
}
