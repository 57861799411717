// Colors
$darkBck: #212122;
$darkTxt: #413E31;
$lightTxt: #8B8273;
$highLight: #de9b42;
$highLightTwo: #de9b42;

// typography
body {
	color: $darkTxt;
}

h1 {
	font-size: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6{
	color: $highLight;
	font-family: 'PT Sans Narrow', sans-serif;
	text-transform: uppercase;
}

a{
	color:$highLight;
}

// navigation
.header{
	background: #000;

	.logo{
		background: transparent;
		padding: 0;
		width: 200px;

		img{
			display: block;
			width: 100%;
		}
	}

	button,.btn{
		background:$highLight;
		color: $darkBck;
	}
}
nav{
	ul{
		li{
			a{
				color:white;
				&:hover,&.active{
					color:$highLightTwo;
					&:before, &:after{
						border-color:$highLightTwo;
					}
				}
			}
		}
	}
}

// home
.home-intro { 
	p {
		color:white;
  }
}
// buttons
button,.btn {
	background:$highLight;
	color:white;
}

// rooms section
.rooms-section {
background-color: #000000;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23de9b42' fill-opacity='0.25'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

// specials
.specials {
	background-color: #ffffff;
	background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23005bc5' fill-opacity='0.12' fill-rule='evenodd'/%3E%3C/svg%3E");
}

// contact
.contact-info{
	dt {
		color: $lightTxt;
	}
}

/*********************************
Media Queries Desktop first
********************************/
 /* Medium Devices, ipad landscape */
@media only screen and (max-width : 1024px) {
}

/* Small Devices, tablets portrait */
@media only screen and (max-width : 768px) {
	.home-intro{ 
		p{
	  	color:$darkTxt;
	  }
	}
}

/* Mobile portrait */
@media only screen and (max-width : 480px) {
	.header{
		nav{		
			ul{
				li{
					a{ color:$highLight;}
				}				
			}
		}
	}
}