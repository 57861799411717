@charset "UTF-8";
/*!
Theme Name: LuxuryRes one page hotel smart site
Theme URI: http://www.luxuryres.com/
Author: Jorge Elvir
Description: LuxuryRes Smart Website
Version: 1.1
*/
// base
@import "base/normalize";
@import "base/typography";
// grid
@import "base/grid";
@import "base/navigation";
@import "base/buttons";
@import "base/flexslider";

// skin
@import "skins/historic-lincoln";

